import {Component, Injector, ViewEncapsulation} from '@angular/core';
import {Album, ALBUM_MODEL} from '../../../models/Album';
import {WpUtils} from '../../web-player-utils';
import {ContextMenuComponent} from '../../context-menu/context-menu.component';
import {Likeable} from '../../users/user-library/user-library.service';
import {ARTIST_MODEL} from '../../../models/Artist';

@Component({
    selector: 'album-context-menu',
    templateUrl: './album-context-menu.component.html',
    styleUrls: ['./album-context-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {'class': 'context-menu'},
})
export class AlbumContextMenuComponent extends ContextMenuComponent<Album> {
    constructor(protected injector: Injector) {
        super(injector);
    }

    /**
     * Copy fully qualified album url to clipboard.
     */
    public copyLinkToClipboard() {
        super.copyLinkToClipboard('album');
    }

    /**
     * Get tracks that should be used by context menu.
     */
    public getTracks() {
        return WpUtils.assignAlbumToTracks(this.data.item.tracks, this.data.item);
    }

    public inLibrary() {
        return this.library.has(this.data.item);
    }

    public removeFromLibrary() {
        this.contextMenu.close();

        if ( ! this.currentUser.isLoggedIn()) {
            return this.router.navigate(['/login']);
        }

        this.library.remove([this.data.item]);
        this.toast.open('Removed from library');
    }
}
