import {Injectable} from '@angular/core';
import {AppHttpClient} from 'common/core/http/app-http-client.service';
import {Observable} from 'rxjs';
import {SearchResponse, SearchResults} from './search-results';
import {BackendResponse} from '@common/core/types/backend-response';
import {MixedArtist} from '../artists/mixed-artist';
import {Album} from '../../models/Album';
import {Track} from '../../models/Track';

interface SearchEverythingParams {
    limit?: number;
    forceLocal?: boolean;
    flatten?: boolean;
    searchChannels?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class Search {
    constructor(private http: AppHttpClient) {
    }

    public everything(query: string, params?: SearchEverythingParams): BackendResponse<SearchResponse> {
        return this.http.get('search/' + encodeURIComponent(query), params);
    }

    public videoId(artist: string, track: Track): Observable<{ title: string, id: string }[]> {
        const artistName = this.encode(artist),
            trackName = this.encode(track.name);
        return this.http.get(`search/audio/${track.id}/${artistName}/${trackName}`);
    }

    public autocompleteArtists(params: { query: string, limit?: number }): BackendResponse<{ results: MixedArtist[] }> {
        return this.http.get('autocomplete/artists', params);
    }

    public autocompleteAlbums(params: { query?: string, limit?: number }): BackendResponse<{ results: Album[] }> {
        return this.http.get('autocomplete/albums', params);
    }

    private encode(string: string): string {
        return encodeURIComponent((string || '').replace('/', '-'));
    }
}
