/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./comment-list-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "../../../../../../common/core/translations/translate.directive";
import * as i4 from "../../../../../../common/core/translations/translations.service";
import * as i5 from "../../../../../../common/core/config/settings.service";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../../../../../../common/core/translations/translate.pipe";
import * as i10 from "../../../../shared/media-image/media-image.component.ngfactory";
import * as i11 from "../../../../shared/media-image/media-image.component";
import * as i12 from "@angular/common";
import * as i13 from "../../../new-comment-form/new-comment-form.component.ngfactory";
import * as i14 from "../../../new-comment-form/new-comment-form.component";
import * as i15 from "../../../track-comments.service";
import * as i16 from "../../../../../../common/auth/current-user";
import * as i17 from "./comment-list-item.component";
import * as i18 from "../../../../player/formatted-duration.service";
var styles_CommentListItemComponent = [i0.styles];
var RenderType_CommentListItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CommentListItemComponent, data: {} });
export { RenderType_CommentListItemComponent as RenderType_CommentListItemComponent };
function View_CommentListItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "reply-button"], ["mat-stroked-button", ""], ["trans", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleNewCommentForm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 4341760, null, 0, i3.TranslateDirective, [i1.ElementRef, i4.Translations, i5.Settings], null, null), i1.ɵdid(2, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Reply"]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 2).disabled || null); var currVal_1 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_CommentListItemComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i9.TransPipe, [i4.Translations]), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "comment-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "media-image", [], null, null, null, i10.View_MediaImageComponent_0, i10.RenderType_MediaImageComponent)), i1.ɵdid(3, 573440, null, 0, i11.MediaImageComponent, [i5.Settings], { media: [0, "media"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "comment-meta"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "comment-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " ", " ", " "])), i1.ɵppd(7, 1), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "comment-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "right-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "created_at"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommentListItemComponent_1)), i1.ɵdid(14, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 2, "new-comment-form", [["class", "comment-marker-ancestor"]], [[2, "visible", null]], [[null, "created"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("created" === en)) {
        var pd_0 = (_co.hideNewCommentForm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_NewCommentFormComponent_0, i13.RenderType_NewCommentFormComponent)), i1.ɵdid(16, 573440, null, 0, i14.NewCommentFormComponent, [i15.TrackCommentsService, i16.CurrentUser], { inReplyTo: [0, "inReplyTo"], visible: [1, "visible"] }, { created: "created" }), i1.ɵpid(131072, i12.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.comment.user; _ck(_v, 3, 0, currVal_0); var currVal_6 = (_co.comment.depth < 5); _ck(_v, 14, 0, currVal_6); var currVal_8 = _co.comment; var currVal_9 = i1.ɵunv(_v, 16, 1, i1.ɵnov(_v, 17).transform(_co.commentFormVisible$)); _ck(_v, 16, 0, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.comment.user.display_name; var currVal_2 = i1.ɵunv(_v, 6, 1, _ck(_v, 7, 0, i1.ɵnov(_v, 0), "at")); var currVal_3 = _co.postedAt(_co.comment.position); _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.comment.content; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.comment.relative_created_at; _ck(_v, 12, 0, currVal_5); var currVal_7 = i1.ɵnov(_v, 16).visible; _ck(_v, 15, 0, currVal_7); }); }
export function View_CommentListItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "comment-list-item", [], [[4, "padding-left", null], [2, "nested", null]], null, null, View_CommentListItemComponent_0, RenderType_CommentListItemComponent)), i1.ɵdid(1, 49152, null, 0, i17.CommentListItemComponent, [i18.FormattedDuration, i15.TrackCommentsService], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).paddingLeft; var currVal_1 = i1.ɵnov(_v, 1).nested; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var CommentListItemComponentNgFactory = i1.ɵccf("comment-list-item", i17.CommentListItemComponent, View_CommentListItemComponent_Host_0, { comment: "comment", parent: "parent" }, {}, []);
export { CommentListItemComponentNgFactory as CommentListItemComponentNgFactory };
