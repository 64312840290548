import * as tslib_1 from "tslib";
import { NgZone } from '@angular/core';
import { PlayerQueue } from '../player-queue.service';
import { Search } from '../../search/search.service';
import { PlayerState } from '../player-state.service';
import { Settings } from 'common/core/config/settings.service';
import * as Dot from 'dot-object';
import { LazyLoaderService } from 'common/core/utils/lazy-loader.service';
import * as i0 from "@angular/core";
import * as i1 from "../player-queue.service";
import * as i2 from "../player-state.service";
import * as i3 from "../../search/search.service";
import * as i4 from "../../../../common/core/config/settings.service";
import * as i5 from "../../../../common/core/utils/lazy-loader.service";
export class YoutubeStrategy {
    /**
     * YoutubeStrategy Constructor.
     */
    constructor(queue, state, search, zone, settings, lazyLoader) {
        this.queue = queue;
        this.state = state;
        this.search = search;
        this.zone = zone;
        this.settings = settings;
        this.lazyLoader = lazyLoader;
        /**
         * Whether player is already bootstrapped.
         */
        this.bootstrapped = false;
        /**
         * Volume that should be set after youtube player is bootstrapped.
         */
        this.pendingVolume = null;
        /**
         * Results of last youtube search for currently cued video.
         */
        this.searchResults = [];
        /**
         * Number of tracks skipped in queue due to youtube iframe or search error.
         */
        this.numberOfTracksSkipped = 0;
    }
    /**
     * Load current queue item and play youtube video.
     */
    play() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.cueTrack(this.queue.getCurrent());
            this.youtube.playVideo();
            this.state.playing = true;
        });
    }
    /**
     * Pause youtube video.
     */
    pause() {
        this.youtube.pauseVideo();
        this.state.playing = false;
    }
    /**
     * stop youtube video.
     */
    stop() {
        this.youtube.stopVideo();
        this.state.playing = false;
    }
    /**
     * Seek to specified time in youtube video.
     */
    seekTo(time) {
        this.youtube && this.youtube.seekTo(time, true);
    }
    /**
     * Get loaded youtube video duration in seconds.
     */
    getDuration() {
        return this.youtube ?
            (this.youtube.getDuration ? this.youtube.getDuration() : 0)
            : 0;
    }
    /**
     * Get elapsed time in seconds since the video started playing
     */
    getCurrentTime() {
        return this.youtube ? this.youtube.getCurrentTime() : 0;
    }
    /**
     * Set youtube player volume.
     */
    setVolume(number) {
        if (!this.youtube || !this.youtube.setVolume) {
            this.pendingVolume = number;
        }
        else {
            this.youtube.setVolume(number);
        }
    }
    /**
     * Mute youtube player.
     */
    mute() {
        this.youtube && this.youtube.mute && this.youtube.mute();
    }
    /**
     * Unmute youtube player.
     */
    unMute() {
        this.youtube && this.youtube.unMute && this.youtube.unMute();
    }
    /**
     * Get track that is currently cued for playback.
     */
    getCuedTrack() {
        return this.cuedTrack;
    }
    /**
     * Check if youtube player is ready.
     */
    ready() {
        return this.bootstrapped;
    }
    /**
     * Fetch youtube ID for specified track if needed and cue it in youtube player.
     */
    cueTrack(track) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.cueing === track || this.cuedTrack === track)
                return;
            this.cueing = track;
            // clear search results, so old search results are not used for new track
            this.searchResults = [];
            this.state.buffering = true;
            if (!track.youtube_id) {
                const artist = Dot.pick('album.artist.name', track) || Dot.pick('artists.0.name', track);
                this.searchResults = (yield this.search.videoId(artist, track).toPromise().catch(() => { }));
                this.assignFirstSearchResult(track);
            }
            return this.bootstrap(track.youtube_id).then(() => {
                this.cueYoutubeVideo(track);
            });
        });
    }
    /**
     * Destroy youtube playback strategy.
     */
    destroy() {
        try {
            this.youtube && this.youtube.destroy();
        }
        catch (e) {
            //
        }
        this.youtube = null;
        this.bootstrapped = false;
        this.cuedTrack = null;
        this.searchResults = [];
    }
    /**
     * Set specified player state.
     */
    setState(name, value) {
        this.zone.run(() => this.state[name] = value);
    }
    /**
     * Cue specified youtube video for specified track.
     */
    cueYoutubeVideo(track) {
        if (track.youtube_id !== this.getYoutubeId()) {
            const suggestedQuality = this.settings.get('youtube.suggested_quality');
            this.youtube.cueVideoById({ videoId: track.youtube_id, suggestedQuality });
        }
        this.cuedTrack = track;
        this.cueing = null;
    }
    /**
     * Get currently cued youtube video ID.
     */
    getYoutubeId() {
        const url = this.youtube.getVideoUrl();
        return url && url.split('v=')[1];
    }
    /**
     * Assign first search result youtube id to track and shift the search array.
     */
    assignFirstSearchResult(track) {
        if (this.searchResults && this.searchResults.length) {
            track.youtube_id = this.searchResults[0].id;
            this.searchResults.shift();
        }
        return track;
    }
    /**
     * Try to play fallback videos from last youtube search.
     */
    tryToPlayFallbackVideos(e) {
        if (!this.searchResults || !this.searchResults.length) {
            return this.handleYoutubeError();
        }
        this.assignFirstSearchResult(this.cuedTrack);
        this.cueYoutubeVideo(this.cuedTrack);
        this.youtube.playVideo();
    }
    /**
     * Handle youtube search or iframe embed error.
     */
    handleYoutubeError() {
        this.cuedTrack = null;
        this.setState('playing', false);
        this.numberOfTracksSkipped++;
        // if we have skipped more then 3 tracks, we can assume
        // that there's a critical issue with youtube search or
        // embed so we should stop the playback and bail.
        if (this.numberOfTracksSkipped <= 2) {
            this.state.firePlaybackEnded();
        }
        return;
    }
    /**
     * Bootstrap youtube playback strategy.
     */
    bootstrap(videoId) {
        if (this.bootstrapped)
            return new Promise(resolve => resolve());
        if (this.bootstrapping)
            return this.bootstrapping;
        this.lazyLoader.loadAsset('https://www.youtube.com/iframe_api', { type: 'js' });
        this.bootstrapping = new Promise(resolve => {
            if (window['onYouTubeIframeAPIReady']) {
                return this.initYoutubePlayer(videoId, resolve);
            }
            else {
                window['onYouTubeIframeAPIReady'] = () => {
                    this.initYoutubePlayer(videoId, resolve);
                };
            }
        });
        return this.bootstrapping;
    }
    /**
     * Initiate youtube iframe player.
     */
    initYoutubePlayer(videoId, resolve) {
        this.youtube = new YT.Player('youtube-player', {
            videoId: videoId,
            playerVars: this.getPlayerVars(),
            events: {
                onReady: () => this.onPlayerReady(resolve),
                onError: this.tryToPlayFallbackVideos.bind(this),
                onStateChange: this.onYoutubePlayerStateChange.bind(this)
            }
        });
    }
    /**
     * Handle youtube player ready event.
     */
    onPlayerReady(resolve) {
        if (this.state.muted)
            this.mute();
        this.bootstrapped = true;
        this.bootstrapping = null;
        resolve();
        this.state.fireReadyEvent();
        if (this.pendingVolume) {
            this.setVolume(this.pendingVolume);
            this.pendingVolume = null;
        }
    }
    /**
     * Handle youtube player state changes.
     */
    onYoutubePlayerStateChange(e) {
        switch (e.data) {
            case 0 /* ENDED */:
                this.state.firePlaybackEnded();
                this.setState('playing', false);
                break;
            case 1 /* PLAYING */:
                this.numberOfTracksSkipped = 0;
                this.setState('playing', true);
                break;
            case 2 /* PAUSED */:
                this.setState('playing', false);
                break;
        }
    }
    /**
     * Get youtube player vars.
     */
    getPlayerVars() {
        return {
            autoplay: 0,
            rel: 0,
            showinfo: 0,
            disablekb: 1,
            fs: 0,
            controls: 0,
            modestbranding: 1,
            iv_load_policy: 3,
            playsinline: 1,
        };
    }
}
YoutubeStrategy.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function YoutubeStrategy_Factory() { return new YoutubeStrategy(i0.ɵɵinject(i1.PlayerQueue), i0.ɵɵinject(i2.PlayerState), i0.ɵɵinject(i3.Search), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i4.Settings), i0.ɵɵinject(i5.LazyLoaderService)); }, token: YoutubeStrategy, providedIn: "root" });
