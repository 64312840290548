import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {MatButtonModule, MatMenuModule, MatPaginatorModule, MatSortModule, MatTableModule} from '@angular/material';
import {BEMUSIC_CONFIG} from './bemusic-config';
import {PagesModule} from '@common/core/pages/shared/pages.module';
import {APP_CONFIG} from '@common/core/config/app-config';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouteReuseStrategy, RouterModule} from '@angular/router';
import {CoreModule} from '@common/core/core.module';
import {AuthModule} from '@common/auth/auth.module';
import {AppRoutingModule} from './app-routing.module';
import {WebPlayerModule} from './web-player/web-player.module';
import {AccountSettingsModule} from '@common/account-settings/account-settings.module';
import {Bootstrapper} from '@common/core/bootstrapper.service';
import {BeMusicBootstrapper} from './bootstrapper.service';
import {CustomRouteReuseStrategy} from '@common/shared/custom-route-reuse-strategy';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule,
        CoreModule.forRoot(),
        AuthModule,

        AppRoutingModule,
        WebPlayerModule,
        // account settings and pages modules must come after web player
        // module for proper account settings and custom page route override
        AccountSettingsModule,
        PagesModule,

        // material
        MatMenuModule,
        MatButtonModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
    ],
    providers: [
        {provide: APP_CONFIG, useValue: BEMUSIC_CONFIG, multi: true},
        {provide: Bootstrapper, useClass: BeMusicBootstrapper},
        {provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
