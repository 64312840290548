/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./waveform.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./comment-bar/comment-bar.component.ngfactory";
import * as i3 from "./comment-bar/comment-bar.component";
import * as i4 from "../track-comments.service";
import * as i5 from "../../../../common/core/ui/overlay-panel/overlay-panel.service";
import * as i6 from "../../../../common/auth/current-user";
import * as i7 from "./waveform.component";
import * as i8 from "../../../../common/core/theme.service";
import * as i9 from "../../player/formatted-duration.service";
import * as i10 from "../../player/player.service";
import * as i11 from "../../web-player-state.service";
import * as i12 from "../tracks.service";
var styles_WaveformComponent = [i0.styles];
var RenderType_WaveformComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WaveformComponent, data: { "animation": [{ type: 7, name: "fadeIn", definitions: [{ type: 0, name: "false", styles: { type: 6, styles: { "opacity": "0" }, offset: null }, options: undefined }, { type: 0, name: "true", styles: { type: 6, styles: { "opacity": "1" }, offset: null }, options: undefined }, { type: 1, expr: "false => true", animation: [{ type: 4, styles: null, timings: "200ms ease-in" }], options: null }], options: {} }] } });
export { RenderType_WaveformComponent as RenderType_WaveformComponent };
export function View_WaveformComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { outerTrack: 0 }), i1.ɵqud(402653184, 2, { progressTrack: 0 }), i1.ɵqud(402653184, 3, { progressHandle: 0 }), i1.ɵqud(402653184, 4, { elapsedTimeEl: 0 }), i1.ɵqud(402653184, 5, { trackLengthEl: 0 }), i1.ɵqud(671088640, 6, { canvas: 0 }), i1.ɵqud(671088640, 7, { overlayCanvas: 0 }), i1.ɵqud(671088640, 8, { el: 0 }), (_l()(), i1.ɵeld(8, 0, [[8, 0], ["seekbar", 1]], null, 10, "div", [["class", "seekbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, [[4, 0], ["elapsedTimeEl", 1]], null, 1, "div", [["class", "elapsed-time"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["0:00"])), (_l()(), i1.ɵeld(11, 0, [[1, 0], ["outerTrack", 1]], null, 5, "div", [["class", "outer-track"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "inner-track"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, [[6, 0], ["canvas", 1]], null, 0, "canvas", [], [[8, "width", 0], [8, "height", 0]], null, null, null, null)), (_l()(), i1.ɵeld(14, 0, [[2, 0], ["progressTrack", 1]], null, 1, "div", [["class", "progress-track"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, [[7, 0], ["overlayCanvas", 1]], null, 0, "canvas", [], [[8, "width", 0], [8, "height", 0]], null, null, null, null)), (_l()(), i1.ɵeld(16, 0, [[3, 0], ["progressHandle", 1]], null, 0, "div", [["class", "progress-handle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, [[5, 0], ["trackLengthEl", 1]], null, 1, "div", [["class", "track-length"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["0:00"])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "comment-bar", [["class", "comment-marker-ancestor"]], null, [[null, "tap"], [null, "panstart"], [null, "panleft"], [null, "panright"]], function (_v, en, $event) { var ad = true; if (("tap" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).onTap($event) !== false);
        ad = (pd_0 && ad);
    } if (("panstart" === en)) {
        var pd_1 = (i1.ɵnov(_v, 20).onPanstart($event) !== false);
        ad = (pd_1 && ad);
    } if (("panleft" === en)) {
        var pd_2 = (i1.ɵnov(_v, 20).onPanHorizontal($event) !== false);
        ad = (pd_2 && ad);
    } if (("panright" === en)) {
        var pd_3 = (i1.ɵnov(_v, 20).onPanHorizontal($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_CommentBarComponent_0, i2.RenderType_CommentBarComponent)), i1.ɵdid(20, 4374528, null, 0, i3.CommentBarComponent, [i4.TrackCommentsService, i5.OverlayPanel, i1.NgZone, i1.ElementRef, i6.CurrentUser], null, null)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.waveWidth; var currVal_1 = _co.waveHeight; _ck(_v, 13, 0, currVal_0, currVal_1); var currVal_2 = _co.waveWidth; var currVal_3 = _co.waveHeight; _ck(_v, 15, 0, currVal_2, currVal_3); }); }
export function View_WaveformComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "waveform", [], [[40, "@fadeIn", 0]], [[null, "tap"], [null, "panend"], [null, "panstart"], [null, "panleft"], [null, "panright"]], function (_v, en, $event) { var ad = true; if (("tap" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onTapAndPanEnd($event) !== false);
        ad = (pd_0 && ad);
    } if (("panend" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTapAndPanEnd($event) !== false);
        ad = (pd_1 && ad);
    } if (("panstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onPanstart($event) !== false);
        ad = (pd_2 && ad);
    } if (("panleft" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onPanHorizontal($event) !== false);
        ad = (pd_3 && ad);
    } if (("panright" === en)) {
        var pd_4 = (i1.ɵnov(_v, 1).onPanHorizontal($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, View_WaveformComponent_0, RenderType_WaveformComponent)), i1.ɵdid(1, 4374528, null, 0, i7.WaveformComponent, [i8.ThemeService, i9.FormattedDuration, i10.Player, i1.NgZone, i11.WebPlayerState, i12.Tracks, i4.TrackCommentsService], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).visible; _ck(_v, 0, 0, currVal_0); }); }
var WaveformComponentNgFactory = i1.ɵccf("waveform", i7.WaveformComponent, View_WaveformComponent_Host_0, { track: "track", album: "album", startPlaybackOnSeek: "startPlaybackOnSeek" }, {}, []);
export { WaveformComponentNgFactory as WaveformComponentNgFactory };
