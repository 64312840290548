import { Routes } from '@angular/router';
import { AlbumComponent } from './albums/album/album.component';
import { AlbumResolver } from './albums/album/album-resolver.service';
import { LibraryTracksComponent } from './users/user-library/library-tracks/library-tracks.component';
import { LibraryAlbumsComponent } from './users/user-library/library-albums/library-albums.component';
import { LibraryArtistsComponent } from './users/user-library/library-artists/library-artists.component';
import { ArtistComponent } from './artists/artist/artist.component';
import { ArtistResolver } from './artists/artist/artist-resolver.service';
import { GenreComponent } from './genres/genre/genre.component';
import { GenreArtistsResolver } from './genres/genre/genre-artists-resolver.service';
import { PlaylistComponent } from './playlists/playlist/playlist.component';
import { PlaylistResolver } from './playlists/playlist/playlist-resolver.service';
import { SearchComponent } from './search/search/search.component';
import { SearchResolver } from './search/search/search-resolver.service';
import { SearchTabValidGuard } from './search/search/search-tab-valid.guard';
import { TrackPageComponent } from './tracks/track-page/track-page.component';
import { TrackPageResolver } from './tracks/track-page/track-page-resolver.service';
import { RadioPageComponent } from './radio-page/radio-page.component';
import { RadioPageResolver } from './radio-page/radio-page-resolver.service';
import { UserLibraryComponent } from './users/user-library/user-library.component';
import { LibraryPlaylistsComponent } from './users/user-library/library-playlists/library-playlists.component';
import { EmptyRouteComponent } from 'common/core/ui/empty-route/empty-route.component';
import { CheckPermissionsGuard } from 'common/guards/check-permissions-guard.service';
import { AuthGuard } from 'common/guards/auth-guard.service';
import { AccountSettingsResolve } from 'common/account-settings/account-settings-resolve.service';
import { AccountSettingsComponent } from 'common/account-settings/account-settings.component';
import { CustomPageComponent } from '@common/core/pages/shared/custom-page/custom-page.component';
import { ChannelShowComponent } from './channels/channel-show/channel-show.component';
import { ChannelResolverService } from '../admin/channels/crupdate-channel-page/channel-resolver.service';
import { PlayHistoryComponent } from './users/user-library/play-history/play-history.component';
import { TrackEmbedComponent } from './tracks/track-embed/track-embed.component';
import { NotificationsPageComponent } from './notifications/notifications-page/notifications-page.component';
import { NotificationsResolverService } from './notifications/notifications-resolver.service';
import { ChannelFallbackHostComponent } from './channels/channel-fallback-host/channel-fallback-host.component';
import { HomepageHostComponent } from './homepage-host/homepage-host.component';
import { NOT_FOUND_ROUTES } from '@common/core/pages/not-found-routes';
const ɵ0 = { name: 'embed track' }, ɵ1 = { name: 'embed album' }, ɵ2 = { willSetSeo: true, parentHomeRoute: true }, ɵ3 = () => import("./users/user-profile-page/user-profile.module.ngfactory")
    .then(m => m.UserProfileModuleNgFactory), ɵ4 = { permissions: ['tracks.create', 'albums.create'] }, ɵ5 = () => import("./../uploading/uploading.module.ngfactory")
    .then(m => m.UploadingModuleNgFactory), ɵ6 = { name: 'channel', failRedirectUri: '/', noReuse: true }, ɵ7 = { name: 'album' }, ɵ8 = { name: 'genre' }, ɵ9 = { name: 'playlist', noReuse: true }, ɵ10 = { name: 'playlist', noReuse: true }, ɵ11 = { name: 'track' }, ɵ12 = { name: 'track' }, ɵ13 = { name: 'search' }, ɵ14 = { name: 'search' }, ɵ15 = { name: 'search' }, ɵ16 = { name: 'artist', noReuse: true }, ɵ17 = { type: 'artist', name: 'radio' }, ɵ18 = { type: 'track', name: 'radio' }, ɵ19 = { name: 'library.tracks', title: 'Your Tracks' }, ɵ20 = { name: 'library.albums', title: 'Your Albums' }, ɵ21 = { name: 'library.artists', title: 'Your Artists' }, ɵ22 = { name: 'library.playlists', title: 'Your Playlists' }, ɵ23 = { name: 'library.history', title: 'Listening History' }, ɵ24 = { name: 'account-settings' }, ɵ25 = { name: 'notifications' }, ɵ26 = { permissions: ['pages.view'] }, ɵ27 = { noReuse: true };
const routes = [
    {
        path: 'track/:id/:name/embed',
        component: TrackEmbedComponent,
        resolve: { api: TrackPageResolver },
        data: ɵ0
    },
    {
        path: 'album/:id/:artist/:album/embed',
        component: TrackEmbedComponent,
        resolve: { api: AlbumResolver },
        data: ɵ1
    },
    {
        path: '',
        component: HomepageHostComponent,
        canActivateChild: [CheckPermissionsGuard],
        data: ɵ2,
        children: [
            {
                path: 'user',
                loadChildren: ɵ3
            },
            {
                path: 'upload',
                data: ɵ4,
                loadChildren: ɵ5
            },
            {
                path: 'channels/:slug',
                component: ChannelShowComponent,
                resolve: { api: ChannelResolverService },
                data: ɵ6
            },
            {
                path: 'album/:id/:artist/:album',
                component: AlbumComponent,
                resolve: { api: AlbumResolver },
                data: ɵ7
            },
            {
                path: 'genre/:name',
                component: GenreComponent,
                resolve: { api: GenreArtistsResolver },
                data: ɵ8
            },
            {
                path: 'playlists/:id',
                component: PlaylistComponent,
                resolve: { api: PlaylistResolver },
                data: ɵ9
            },
            {
                path: 'playlists/:id/:name',
                component: PlaylistComponent,
                resolve: { api: PlaylistResolver },
                data: ɵ10
            },
            {
                path: 'track/:id',
                component: TrackPageComponent,
                resolve: { api: TrackPageResolver },
                data: ɵ11
            },
            {
                path: 'track/:id/:name',
                component: TrackPageComponent,
                resolve: { api: TrackPageResolver },
                data: ɵ12
            },
            { path: 'search', component: SearchComponent, data: ɵ13 },
            {
                path: 'search/:query',
                component: SearchComponent,
                resolve: { results: SearchResolver },
                data: ɵ14
            },
            {
                path: 'search/:query/:tab',
                component: SearchComponent,
                resolve: { results: SearchResolver },
                canActivate: [SearchTabValidGuard],
                data: ɵ15
            },
            {
                path: 'artist/:id/:name',
                component: ArtistComponent,
                resolve: { api: ArtistResolver },
                data: ɵ16,
                children: [
                    { path: ':tabName', component: EmptyRouteComponent },
                ]
            },
            {
                path: 'radio/artist/:id/:name',
                component: RadioPageComponent,
                resolve: { radio: RadioPageResolver },
                data: ɵ17
            },
            {
                path: 'radio/track/:id/:name',
                component: RadioPageComponent,
                resolve: { radio: RadioPageResolver },
                data: ɵ18
            },
            {
                path: 'library',
                component: UserLibraryComponent,
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
                children: [
                    { path: '', redirectTo: 'songs', pathMatch: 'full' },
                    {
                        path: 'songs',
                        component: LibraryTracksComponent,
                        data: ɵ19
                    },
                    {
                        path: 'albums',
                        component: LibraryAlbumsComponent,
                        data: ɵ20
                    },
                    {
                        path: 'artists',
                        component: LibraryArtistsComponent,
                        data: ɵ21
                    },
                    {
                        path: 'playlists',
                        component: LibraryPlaylistsComponent,
                        data: ɵ22
                    },
                    {
                        path: 'history',
                        component: PlayHistoryComponent,
                        data: ɵ23
                    },
                ]
            },
            { path: 'account-settings', redirectTo: 'account/settings' },
            {
                path: 'account/settings',
                component: AccountSettingsComponent,
                resolve: { api: AccountSettingsResolve },
                canActivate: [AuthGuard],
                data: ɵ24
            },
            {
                path: 'account/notifications',
                component: NotificationsPageComponent,
                resolve: { api: NotificationsResolverService },
                canActivate: [AuthGuard],
                data: ɵ25,
            },
            {
                path: 'pages/:id/:slug',
                component: CustomPageComponent,
                data: ɵ26
            },
            {
                path: ':slug',
                resolve: { api: ChannelResolverService },
                component: ChannelFallbackHostComponent,
                data: ɵ27,
            },
            ...NOT_FOUND_ROUTES,
        ]
    },
];
export class WebPlayerRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27 };
