import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {catchError, mergeMap} from 'rxjs/operators';
import {EMPTY, of} from 'rxjs';
import {Notification} from './notification';
import {NotificationsService} from './notifications.service';
import {CurrentUser} from '@common/auth/current-user';
import {PaginatedBackendResponse} from '@common/core/types/pagination/paginated-backend-response';

@Injectable({
    providedIn: 'root'
})
export class NotificationsResolverService implements Resolve<PaginatedBackendResponse<Notification>> {
    constructor(
        private notifications: NotificationsService,
        private currentUser: CurrentUser,
        private router: Router,
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): PaginatedBackendResponse<Notification> {
        return this.notifications.all({userId: this.currentUser.get('id')}).pipe(
            catchError(() => {
                this.router.navigate(['/']);
                return EMPTY;
            }),
            mergeMap(response => {
                if (response.pagination) {
                    return of(response);
                } else {
                    this.router.navigate(['/']);
                    return EMPTY;
                }
            })
        );
    }
}
