import {Injectable} from '@angular/core';
import {AppHttpClient} from 'common/core/http/app-http-client.service';
import {Dlc} from '../../models/Dlc';
import {BackendResponse} from '@common/core/types/backend-response';

@Injectable({
    providedIn: 'root'
})
export class Dl {
    constructor(private http: AppHttpClient) {}

    public get(trackId: number): BackendResponse<{dlc: Dlc}> {
        return this.http.get(`dl/${trackId}`);
    }


}

