import { Router } from '@angular/router';
import { Settings } from '../../config/settings.service';
import { CustomPageComponent } from './custom-page/custom-page.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../config/settings.service";
export class CustomHomepage {
    constructor(router, settings) {
        this.router = router;
        this.settings = settings;
        this.defaultComponents = [
            { name: 'login', routeConfig: { redirectTo: '/login', pathMatch: 'full' } },
            { name: 'register', routeConfig: { redirectTo: '/register', pathMatch: 'full' } },
        ];
        this.menuCategories = [
            { name: 'Custom Pages', route: { component: CustomPageComponent } },
        ];
    }
    select(custom) {
        this.defaultComponents = this.defaultComponents.concat(custom.routes || []);
        this.menuCategories = this.menuCategories.concat(custom.menuCategories);
        const type = this.settings.get('homepage.type') || 'default', value = this.settings.get('homepage.value');
        if (type === 'default' || type == null) {
            return;
        }
        else if (type === 'component') {
            return this.setComponentAsHomepage(value);
        }
        else {
            const category = this.menuCategories.find(c => c.name === type);
            if (category) {
                const route = Object.assign({}, category.route, { data: { id: value } });
                this.addRoute(route);
            }
        }
    }
    isOnlyForGuests() {
        const type = this.settings.get('homepage.type', 'default'), value = this.settings.get('homepage.value');
        return type === 'component' && (value === 'login' || value === 'register');
    }
    getComponents() {
        return this.defaultComponents;
    }
    setComponentAsHomepage(name) {
        const page = this.defaultComponents.find(comp => comp.name === name);
        if (!page)
            return;
        this.addRoute(Object.assign({}, page.routeConfig), page.makeRoot);
    }
    addRoute(route, makeRoot = false) {
        const parent = makeRoot ? null : this.getParentHomeRoute();
        route = this.prepareRoute(route);
        // use child routes if parent exists, otherwise use base router config
        const routes = parent ? parent.children : this.router.config;
        // remove already existing home route
        const i = routes.findIndex(r => r.path === '');
        // add new route specified by user
        if (i > -1) {
            routes[i] = route;
        }
        else {
            routes.unshift(route);
        }
    }
    getParentHomeRoute() {
        return this.router.config.find(route => {
            return route.data && route.data.parentHomeRoute;
        });
    }
    prepareRoute(route) {
        route.path = '';
        if (!route.data)
            route.data = {};
        route.data.name = 'home';
        return route;
    }
}
CustomHomepage.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomHomepage_Factory() { return new CustomHomepage(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Settings)); }, token: CustomHomepage, providedIn: "root" });
