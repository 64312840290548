import {Component, Inject, Optional, ViewEncapsulation, OnInit, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

import {Subscription} from 'rxjs';
/**
import {Observable, of, timer} from 'rxjs';
import { Observable } from 'rxjs/Observable';
*/

import { timer, Observable } from 'rxjs';

export interface DlModalData {
    dl: string;
	zmem: string;
	zid: number;
	zemail: string;
}

@Component({
    selector: 'dl-modal',
    templateUrl: './dl-modal.component.html',
    styleUrls: ['./dl-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DlModalComponent implements OnInit, OnDestroy {
    public dl: string;
	public zmem: string;
	public zid: number;
	public zemail: string;

	public showloader: boolean = false;      
	private subscription: Subscription;
	private timer: Observable<any>;

	public ngOnInit() {	
		if (this.zmem == 'N') {
			this.setTimer();			
			this.showloader = true;
		}
	}
	public ngOnDestroy() {
		if ( this.subscription && this.subscription instanceof Subscription) {
		  this.subscription.unsubscribe();
		}
	}

	public setTimer(){
		this.showloader   = true;
		this.timer        = timer(0,1000); 
		this.subscription = this.timer.subscribe( val => {		
			/**
			console.log(val)
			*/			
			let c = 0;
			let t = 15;
			c = t - val;
			document.getElementById("countdown").innerHTML = c.toFixed();
			if (c <= 1) {						
				this.subscription.unsubscribe();
				this.showloader = false;	
			}
		});
	}

    constructor(
        private dialogRef: MatDialogRef<DlModalComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: DlModalData,
    ) {
        this.dl = data.dl;
		this.zmem = data.zmem;
		this.zid = data.zid;
		this.zemail = data.zemail;
    }
}
