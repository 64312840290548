import {Component, Injector, ViewEncapsulation} from '@angular/core';
import {TrackContextMenuComponent} from '../../tracks/track-context-menu/track-context-menu.component';
import {Player} from '../../player/player.service';
import {UserLibrary} from '../../users/user-library/user-library.service';
import {Playlists} from '../playlists.service';
import {Lyrics} from '../../lyrics/lyrics.service';

import {Dl} from "../../dl/dl.service";

@Component({
    selector: 'playlist-track-context-menu',
    templateUrl: './playlist-track-context-menu.component.html',
    encapsulation: ViewEncapsulation.None,
    host: {'class': 'context-menu'},
})
export class PlaylistTrackContextMenuComponent extends TrackContextMenuComponent {
    constructor(
        protected player: Player,
        protected userLibrary: UserLibrary,
        protected injector: Injector,
        protected playlists: Playlists,
        protected lyrics: Lyrics,
        protected dl: Dl,			
    ) {
        super(player, userLibrary, injector, lyrics, dl);
    }

    public removeFromPlaylist() {
        this.playlists.removeTracks(this.data.playlistId, this.getTracks()).subscribe();
        this.contextMenu.close();
    }
}
