/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./player-seekbar.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./player-seekbar.component";
import * as i3 from "../../formatted-duration.service";
import * as i4 from "../../player.service";
var styles_PlayerSeekbarComponent = [i0.styles];
var RenderType_PlayerSeekbarComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PlayerSeekbarComponent, data: {} });
export { RenderType_PlayerSeekbarComponent as RenderType_PlayerSeekbarComponent };
export function View_PlayerSeekbarComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { outerTrack: 0 }), i1.ɵqud(402653184, 2, { progressTrack: 0 }), i1.ɵqud(402653184, 3, { progressHandle: 0 }), i1.ɵqud(402653184, 4, { elapsedTimeEl: 0 }), i1.ɵqud(402653184, 5, { trackLengthEl: 0 }), (_l()(), i1.ɵeld(5, 0, [[4, 0], ["elapsedTimeEl", 1]], null, 1, "div", [["class", "elapsed-time"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["0:00"])), (_l()(), i1.ɵeld(7, 0, [[1, 0], ["outerTrack", 1]], null, 3, "div", [["class", "outer-track"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "inner-track"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, [[2, 0], ["progressTrack", 1]], null, 0, "div", [["class", "progress-track"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, [[3, 0], ["progressHandle", 1]], null, 0, "div", [["class", "progress-handle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, [[5, 0], ["trackLengthEl", 1]], null, 1, "div", [["class", "track-length"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["0:00"]))], null, null); }
export function View_PlayerSeekbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "player-seekbar", [], null, [[null, "tap"], [null, "panend"], [null, "panstart"], [null, "panleft"], [null, "panright"]], function (_v, en, $event) { var ad = true; if (("tap" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onTapAndPanEnd($event) !== false);
        ad = (pd_0 && ad);
    } if (("panend" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTapAndPanEnd($event) !== false);
        ad = (pd_1 && ad);
    } if (("panstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onPanstart($event) !== false);
        ad = (pd_2 && ad);
    } if (("panleft" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onPanHorizontal($event) !== false);
        ad = (pd_3 && ad);
    } if (("panright" === en)) {
        var pd_4 = (i1.ɵnov(_v, 1).onPanHorizontal($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, View_PlayerSeekbarComponent_0, RenderType_PlayerSeekbarComponent)), i1.ɵdid(1, 4374528, null, 0, i2.PlayerSeekbarComponent, [i1.ElementRef, i3.FormattedDuration, i4.Player, i1.NgZone], null, null)], null, null); }
var PlayerSeekbarComponentNgFactory = i1.ɵccf("player-seekbar", i2.PlayerSeekbarComponent, View_PlayerSeekbarComponent_Host_0, { track: "track", album: "album", startPlaybackOnSeek: "startPlaybackOnSeek" }, {}, []);
export { PlayerSeekbarComponentNgFactory as PlayerSeekbarComponentNgFactory };
