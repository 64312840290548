import { AppHttpClient } from 'common/core/http/app-http-client.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/core/http/app-http-client.service";
export class Dl {
    constructor(http) {
        this.http = http;
    }
    get(trackId) {
        return this.http.get(`dl/${trackId}`);
    }
}
Dl.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Dl_Factory() { return new Dl(i0.ɵɵinject(i1.AppHttpClient)); }, token: Dl, providedIn: "root" });
