<button class="close-button no-style" mat-dialog-close tabindex="-1">
    <mat-icon svgIcon="close"></mat-icon>
</button>

<mat-dialog-content>
<div *ngIf="showloader">
	<div>
		<br>Get faster downloads<br>
		and no waiting period<br><br>
		<a href="https://www.lenoz.com/secure_checkout.htm?i={{zid}}&e={{zemail}}" target="_blank">Get Pro Membership</a><br><br>		
		<span id="countdown"></span><br><br>
		<img style="display: block; margin-left: auto; margin-right: auto;" src="http://www.lenoz.com/img/zspinner-63px.gif">	
	</div>
</div>

<div *ngIf="!showloader">
    <div>
		<br>Click link below<br><br>
		File saved in your browsers default download folder<br><br>
		Google Chrome browser use keys CTRL J to show downloads<br><br>
	</div>
    <div class="dl" [innerHtml]="dl"></div>	
</div>
</mat-dialog-content>